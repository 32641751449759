<template>
  <ion-card class="card-outline ion-no-margin">
    <!-- Subtotal -->
    <ion-item lines="none">
      <ion-label>
        <h4>Subtotal</h4>
      </ion-label>
      <ion-label slot="end">
        <p>
          ${{ subtotal.toLocaleString("en", { minimumFractionDigits: 2 }) }}
        </p>
      </ion-label>
    </ion-item>

    <!-- Estimated Taxes & Fees -->
    <ion-item lines="none">
      <ion-label>
        <h4>Taxes & Fees</h4>
      </ion-label>
      <ion-label slot="end">
        <p>
          ${{
            taxesAndFees.toLocaleString("en", {
              minimumFractionDigits: 2,
            })
          }}
        </p>
      </ion-label>
    </ion-item>

    <!-- Total -->
    <ion-item lines="none">
      <ion-label>
        <h4><strong>Total</strong></h4>
      </ion-label>

      <ion-label slot="end">
        <p>
          <strong
            >${{
              total.toLocaleString("en", { minimumFractionDigits: 2 })
            }}</strong
          >
        </p>
      </ion-label>
    </ion-item>
    <hr />

    <!-- Payments -->
    <ion-item lines="none">
      <ion-label>
        <h4>Payments</h4>
      </ion-label>

      <ion-label slot="end">
        <p>
          ${{
            paymentsTotal.toLocaleString("en", { minimumFractionDigits: 2 })
          }}
        </p>
      </ion-label>
    </ion-item>

    <!-- Balance -->
    <ion-item lines="none">
      <ion-label>
        <h4><strong>Balance</strong></h4>
      </ion-label>

      <ion-label slot="end">
        <p>
          <strong
            >${{
              balance.toLocaleString("en", { minimumFractionDigits: 2 })
            }}</strong
          >
        </p>
      </ion-label>
    </ion-item>
  </ion-card>
</template>

<script>
import { IonCard, IonItem, IonLabel } from "@ionic/vue";

export default {
  props: {
    folio: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonCard,
    IonItem,
    IonLabel,
  },

  computed: {
    line_items() {
      let line_items = this.folio.transactions.filter(
        (x) => x.type === "line_item"
      );

      return line_items;
    },

    payments() {
      return this.folio.transactions.filter((x) => {
        if (
          x.type === "charge" &&
          x.stripe_payment_intent_status === "succeeded"
        )
          return true;
        return false;
      });
    },

    subtotal() {
      let subtotal = this.line_items.reduce(
        (partial_sum, a) => partial_sum + a.price_final,
        0
      );

      return subtotal;
    },

    taxesAndFees() {
      let taxesAndFees = this.line_items.reduce(
        (partial_sum, a) => partial_sum + a.price_taxes,
        0
      );

      return taxesAndFees;
    },

    total() {
      return Number(this.subtotal) + Number(this.taxesAndFees);
    },

    paymentsTotal() {
      let paymentsTotal = this.payments.reduce(
        (partial_sum, a) => partial_sum + a.amount,
        0
      );

      return paymentsTotal;
    },

    balance() {
      let paymentsTotal = this.payments.reduce(
        (partial_sum, a) => partial_sum + (a.stripe_charge_amount/100 - a.stripe_refund_amount/100),
        0
      );

      return Number(paymentsTotal) + Number(this.total);
    },
  },
};
</script>