<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons>
          <a @click="closeModal">Cancel</a>
        </ion-buttons>
        <ion-title>Edit Session</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <v-form @submit="saveSession">
        <ion-grid>
          <ion-row>
            <ion-col size="12">
              <base-field-display label-text="Client" lines="none">
                <p>{{ session.engagement.user.fullname }}</p>
              </base-field-display>
            </ion-col>

            <!-- Provider -->
            <ion-col size="12">
              <base-input label-text="Provider">
                <v-field name="absence" v-slot="{ field }" v-model="session.provider_id">
                  <select v-bind="field" class="regular-select">
                    <option v-for="provider_profile in provider_profiles" :key="provider_profile.user.id"
                      :value="provider_profile.user.id">
                      {{ provider_profile.user.fullname }}
                    </option>
                  </select>
                </v-field>
              </base-input>
              <v-error-message name="absence" class="error-message">
              </v-error-message>
            </ion-col>

            <ion-col size="12">
              <base-field-display label-text="Shift" lines="none">
                <p class="ion-text-capitalize">{{ session.shift }}</p>
              </base-field-display>
            </ion-col>

            <ion-col size="6">
              <base-field-display label-text="Start Date & Time" lines="none">
                <p v-if="session.start_datetime">
                  {{ format(parseISO(session.start_datetime), "EEE, MMMM d, yyyy 'at' h:mma") }}
                </p>
              </base-field-display>
            </ion-col>

            <ion-col size="6">
              <base-field-display label-text="End Date & Time" lines="none">
                <p v-if="session.end_datetime">
                  {{ format(parseISO(session.end_datetime), "EEE, MMMM d, yyyy 'at' h:mma") }}
                </p>
              </base-field-display>
            </ion-col>

            <!-- General Notes -->
            <ion-col size="12">
              <base-input label-text="Coordinator Notes (internal)">
                <v-field name="notes" v-slot="{ field }" v-model="session.notes">
                  <ion-textarea :auto-grow="true" rows="1" v-bind="field" placeholder="" autocapitalize autocorrect>
                  </ion-textarea>
                </v-field>
              </base-input>
              <v-error-message name="notes" class="error-message">
              </v-error-message>
            </ion-col>
          </ion-row>

          <!-- Buttons -->
          <ion-row>
            <ion-col size="6">
              <ion-button expand="block" color="danger" class="margin-y-16" @click="confirmDelete">
                Delete Session
              </ion-button>
            </ion-col>
            <ion-col size="6">
              <ion-button type="submit" expand="block" class="margin-y-16">
                Save
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </v-form>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonTextarea,
  modalController,
  alertController
} from "@ionic/vue";
import { parseISO, format } from "date-fns"
import { Field, Form, ErrorMessage } from "vee-validate";

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonTextarea,
    VForm: Form,
    VField: Field,
    VErrorMessage: ErrorMessage,
  },

  props: {
    event: {
      type: Object
    },
  },

  data() {
    return {
      format,
      parseISO,
      session: {
        engagement: {
          user: {}
        },
        provider: {},
      },
      provider_profiles: []
    };
  },

  watch: {
  },

  async mounted() {
    await this.fetchSession();
    this.fetchProviderProfiles()
  },

  methods: {
    /**
     * Fetch Provider Profiles
     */
    async fetchProviderProfiles() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/provider_profiles/`)
        .then((response) => { this.provider_profiles = response.data.provider_profiles; })
        .catch((error) => { console.log(error) });
    },

    /**
     * Fetch Session
     */
    async fetchSession() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/sessions/${this.event._def.publicId}`)
        .then((response) => { this.session = response.data.session; })
        .catch((error) => { console.log(error) });
    },

    /**
     * Save Session
     */
    async saveSession() {
      const config = {
        method: "patch",
        url: `${process.env.VUE_APP_API}/sessions/${this.session.id}`,
        data: this.session,
      };

      await this.axios(config)
        .then((response) => {
          this.session = response.data.session;

          return modalController.dismiss(null, 'save');
        })
        .catch((error) => { console.log(error); });
    },


    /**
     * Confirm Delete with Alert
     */
    async confirmDelete() {
      const alert = await alertController.create({
        header: "Are you sure?",
        message: "This will delete this session and all information",
        buttons: [
          { text: 'Cancel' },
          {
            text: 'Yes, Delete',
            handler: () => { this.deleteSession() },
          },
        ],
      });

      await alert.present();
    },

    /**
     * Delete Session
     */
    async deleteSession() {
      const config = {
        method: "delete",
        url: `${process.env.VUE_APP_API}/sessions/${this.session.id}`,
      };

      await this.axios(config)
        .then(() => { return modalController.dismiss(null, 'save'); })
        .catch((error) => { console.log(error); });
    },

    /**
     * Close this Modal
     */
    closeModal() {
      return modalController.dismiss(null, 'cancel');
    }
  },
};
</script>