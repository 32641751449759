<template>
  <base-layout-two :page-title="`Engagement for ${engagement.user.first_name} ${engagement.user.last_name}`"
    page-default-back-link="/engagements" end-button-text="Edit"
    :end-button-url="`/engagements/${$route.params.id}/edit`">
    <section class="ion-padding background-white">
      <div>
        <span class="h1">
          {{ engagement.user.first_name }} {{ engagement.user.last_name }}
        </span>

        <ion-chip :color="engagement.status === 'confirmed' ? 'primary' : 'danger'">
          <ion-label class="ion-text-capitalize">
            {{ engagement.status }}
          </ion-label>
        </ion-chip>
      </div>

      <p>📍 {{ address }}</p>
    </section>

    <section class="padding-x-8 margin-y-8">
      <!-- Engagement Details -->
      <base-card title="Engagement Details">
        <ion-grid class="ion-no-padding">
          <ion-row>
            <!-- Status -->
            <ion-col size="12">
              <base-field-display label-text="Address">
                <p>{{ address }}</p>
              </base-field-display>
            </ion-col>

            <!-- Status -->
            <ion-col size="12" size-lg="6">
              <base-field-display label-text="Status">
                <p class="ion-text-capitalize">{{ engagement.status }}</p>
              </base-field-display>
            </ion-col>

            <!-- Confirmation -->
            <ion-col size="12" size-lg="6">
              <base-field-display label-text="Confirmation">
                <p>{{ engagement.confirmation }}</p>
              </base-field-display>
            </ion-col>

            <!-- Day Sessions -->
            <ion-col size="12" size-lg="6">
              <base-field-display label-text="Day Sessions">
                <p>{{ engagement.sessions_day }} sessions</p>
              </base-field-display>
            </ion-col>

            <!-- Night Sessions -->
            <ion-col size="12" size-lg="6">
              <base-field-display label-text="Night Sessions">
                <p>{{ engagement.sessions_night }} sessions</p>
              </base-field-display>
            </ion-col>

            <!-- Price Final -->
            <ion-col size="12" size-lg="6">
              <base-field-display label-text="Subtotal (excluding taxes & fees)">
                <p>
                  ${{ formatCurrency(engagement.price_final) }}
                </p>
              </base-field-display>
            </ion-col>

            <!-- Taxes -->
            <ion-col size="12" size-lg="6">
              <base-field-display label-text="Taxes & fees">
                <p>
                  ${{ formatCurrency(engagement.price_taxes) }}
                </p>
              </base-field-display>
            </ion-col>

            <!-- Total -->
            <ion-col size="12" size-lg="6">
              <base-field-display label-text="Total">
                <p>
                  ${{ formatCurrency(engagement.price_total) }}
                </p>
              </base-field-display>
            </ion-col>

            <!-- General Notes -->
            <ion-col size="12">
              <base-field-display label-text="General Notes">
                <p>{{ engagement.general_notes }}</p>
              </base-field-display>
            </ion-col>
          </ion-row>
        </ion-grid>
      </base-card>

      <!-- Schedule -->
      <base-card title="Schedule" class="margin-top-8">
        <ion-grid>
          <ion-row>
            <ion-col>
              <h4>Scheduled Sessions</h4>
              <p>{{ scheduledDaySessions }}/{{ engagement.sessions_day }} Days Scheduled</p>
              <p>{{ scheduledNightSessions }}/{{ engagement.sessions_night }} Nights Scheduled</p>
            </ion-col>
            <ion-col>
              <h4>Assigned Sessions</h4>
              <p>{{ assignedDaySessions }}/{{ engagement.sessions_day }} Days Assigned</p>
              <p>{{ assignedNightSessions }}/{{ engagement.sessions_night }} Nights Assigned</p>
            </ion-col>
          </ion-row>
        </ion-grid>

        <h4>Admin - Drag sessions onto the calendar to add</h4>
        <div id="sessions" class="margin-bottom-24">
          <span class="fc-event-morning">Morning (4h)</span>
          <span class="fc-event-afternoon">Afternoon (4h)</span>
          <span class="fc-event-evening">Evening (4h)</span>
          <span class="fc-event-night">Night (8h)</span>
        </div>

        <full-calendar ref="fullCalendar" :options="calendarOptions" style="height: 1200px"
          v-if="fetchedEvents"></full-calendar>
      </base-card>


      <!-- Billing -->
      <base-card title="Folio" class="margin-top-8" link-text="See More"
        :link-url="`/users/${engagement.user.id}/folios/${engagement.folio.id}`">
        <folio-preview-card :folio="engagement.folio"></folio-preview-card>
      </base-card>
    </section>
  </base-layout-two>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonChip, IonLabel, modalController, alertController } from "@ionic/vue";
import FolioPreviewCard from "@/components/users/folios/FolioPreviewCard.vue";
import SessionEditModal from "@/components/sessions/SessionEditModal.vue"
import { formatCurrency } from "@/util/helpers";

import FullCalendar from "@fullcalendar/vue3";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import { format, startOfMonth, endOfMonth, addHours } from "date-fns"

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonChip,
    IonLabel,
    FolioPreviewCard,
    FullCalendar,
  },

  data() {
    return {
      engagement: {
        folio: {
          transactions: [],
        },
        user: {},
      },
      sessions: [],
      fetchedEvents: false,
      calendarOptions: {
        schedulerLicenseKey: "0462219735-fcs-1641598496",
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          listPlugin,
          interactionPlugin,
        ],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,listMonth",
        },
        customButtons: {
          prev: { click: () => { this.prev() } },
          next: { click: () => { this.next() } },
          today: {
            text: "today",
            click: () => { this.today() }
          }
        },
        initialView: "dayGridMonth",
        editable: false,
        droppable: true,
        selectable: false,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        drop: this.handleDrop,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        events: this.fetchSessions,
      },
      formatCurrency
    };
  },

  computed: {
    address() {
      return `${this.engagement.street_number} ${this.engagement.route}, ${(this.engagement.unit_number) ? `${this.engagement.unit_number}, ` : ''} ${this.engagement.locality}, ${this.engagement.administrative_area_level_1} ${this.engagement.postal_code}`
    },

    scheduledDaySessions() {
      return this.sessions.filter((x) => x.shift !== 'night').length
    },

    scheduledNightSessions() {
      return this.sessions.filter((x) => x.shift === 'night').length
    },

    assignedDaySessions() {
      return this.sessions.filter((x) => x.shift !== 'night' && x.provider_id !== null).length
    },

    assignedNightSessions() {
      return this.sessions.filter((x) => x.shift === 'night' && x.provider_id !== null).length
    },

    engagementCheckoutLink() {
      return `${process.env.VUE_APP_GUEST_PORTAL}/athome/finish/checkout/${this.engagement.code}`;
    },
  },

  ionViewWillEnter() {
    this.setupDraggable()

    this.fetchEngagement();
    this.fetchSessions();
  },

  methods: {
    async fetchEngagement() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/engagements/${this.$route.params.id}`)
        .then((response) => {
          this.engagement = response.data.engagement;
        })
        .catch((error) => { console.log(error) });
    },

    /**
     * FullCalendar - Setup Draggable Events for Day and Nights
     */
    setupDraggable() {
      new Draggable(document.getElementById("sessions"), {
        itemSelector: ".fc-event-morning",
        // eslint-disable-next-line no-unused-vars
        eventData: function (eventEl) {
          let event = { create: false };
          return event;
        }
      });

      new Draggable(document.getElementById("sessions"), {
        itemSelector: ".fc-event-afternoon",
        // eslint-disable-next-line no-unused-vars
        eventData: function (eventEl) {
          let event = { create: false };
          return event;
        }
      });

      new Draggable(document.getElementById("sessions"), {
        itemSelector: ".fc-event-evening",
        // eslint-disable-next-line no-unused-vars
        eventData: function (eventEl) {
          let event = { create: false };
          return event;
        }
      });

      new Draggable(document.getElementById("sessions"), {
        itemSelector: ".fc-event-night",
        // eslint-disable-next-line no-unused-vars
        eventData: function (eventEl) {
          let event = { create: false };
          return event;
        }
      });
    },

    /**
    * FullCalendar - CalendarAPI
    */
    calendarApi() {
      return this.$refs.fullCalendar.getApi();
    },

    /**
     * FullCalendar - Next in Calendar
     */
    next() {
      this.calendarApi().next();

      let date = this.calendarApi().getDate()
      this.range_start = format(startOfMonth(date), 'yyyy-MM-dd')
      this.range_end = format(endOfMonth(date), 'yyyy-MM-dd')
      this.calendarApi().refetchEvents()
    },

    /**
     * FullCalendar - Prev in Calendar
     */
    prev() {
      this.calendarApi().prev();

      let date = this.calendarApi().getDate()
      this.range_start = format(startOfMonth(date), 'yyyy-MM-dd')
      this.range_end = format(endOfMonth(date), 'yyyy-MM-dd')
      this.calendarApi().refetchEvents()
    },

    /**
     * FullCalendar - Prev in Calendar
     */
    today() {
      this.calendarApi().today();

      let date = this.calendarApi().getDate()
      this.range_start = format(startOfMonth(date), 'yyyy-MM-dd')
      this.range_end = format(endOfMonth(date), 'yyyy-MM-dd')
      this.calendarApi().refetchEvents()
    },

    /**
    * FullCalendar - Handle Drop
    * Handle when an event is dragged onto the calendar
    * Creates the event in the API
    */
    async handleDrop(info) {
      // Check if user can still make these types of sessions
      if (
        (info.draggedEl.className.includes('night') && this.scheduledNightSessions >= this.engagement.sessions_night) ||
        (!info.draggedEl.className.includes('night') && this.scheduledDaySessions >= this.engagement.sessions_day)
      ) {
        const alert = await alertController.create({
          header: "Sessions Limit Reached",
          message: "Unable to add more sessions of this type",
          buttons: [{ text: 'Okay' }],
        });

        await alert.present();
        return
      }
      else {
        // Define the Event Type
        let shift = 'morning'
        let startDatetime = new Date(info.date)
        startDatetime.setUTCHours(12, 0, 0, 0);
        let endDatetime = addHours(new Date(startDatetime), 4)

        if (info.draggedEl.className.includes('afternoon')) {
          shift = 'afternoon'
          startDatetime = addHours(new Date(startDatetime), 5)
          endDatetime = addHours(new Date(startDatetime), 4)
        }
        else if (info.draggedEl.className.includes('evening')) {
          shift = 'evening'
          startDatetime = addHours(new Date(startDatetime), 10)
          endDatetime = addHours(new Date(startDatetime), 4)
        }
        else if (info.draggedEl.className.includes('night')) {
          shift = 'night'
          startDatetime = addHours(new Date(startDatetime), 14)
          endDatetime = addHours(new Date(startDatetime), 8)
        }

        const config = {
          method: "post",
          url: `${process.env.VUE_APP_API}/sessions`,
          data: {
            engagement_id: this.engagement.id,
            status: 'scheduled',
            start_datetime: startDatetime.toISOString().replace("T", " ").replace("Z", ""),
            end_datetime: endDatetime.toISOString().replace("T", " ").replace("Z", ""),
            shift: shift,
          },
        };

        this.axios(config)
          .then(() => { this.calendarApi().refetchEvents() })
          .catch((error) => { console.log(error); });
      }
    },

    /**
     * Fetch Sessions
     * This is to be used as a function in Event Source
     */
    async fetchSessions(info, successCallback, failureCallback) {
      console.log('fetchSessions');
      await this.axios
        .get(`${process.env.VUE_APP_API}/sessions?engagement_id=${this.$route.params.id}`)
        .then((response) => {
          if (response.data.sessions) {
            this.sessions = response.data.sessions

            console.log(this.sessions);

            // Convert Sessions into Calendar Event
            let events = this.sessions.map((x) => {
              // Setup the Color
              let backgroundColor = "#fff"

              if (x.shift === 'morning') {
                backgroundColor = "#3CB371"
              }
              else if (x.shift === 'afternoon') {
                backgroundColor = "#1E90FF"
              }
              else if (x.shift === 'evening') {
                backgroundColor = "#BA55D3"
              }
              else if (x.shift === 'night') {
                backgroundColor = "#4B0082"
              }

              let borderColor = backgroundColor
              let textColor = "#fff"

              // No Provider, we invert the colros
              if (!x.provider) {
                textColor = backgroundColor
                backgroundColor = "#fff"
              }

              // Customize the Event title
              let title = `${x.shift[0].toUpperCase() + x.shift.slice(1)} (***Unassigned***)`
              if (x.provider)
                title = `${x.shift[0].toUpperCase() + x.shift.slice(1)} (${x.provider.fullname})`

              return {
                ...x,
                title: title,
                start: x.start_datetime,
                end: x.end_datetime,
                backgroundColor: backgroundColor,
                borderColor: borderColor,
                textColor: textColor
              }
            })

            this.fetchedEvents = true

            if (successCallback)
              successCallback(events)
          }
          else {
            console.log(info)
            failureCallback('error')
          }

          this.fetchedEvents = true
        })
        .catch((error) => { console.log(error) });
    },

    /**
     * FullCalendar - Handle when an event is clicked
     * Loads a Shift Edit Modal
     * 
     * @param {*} event
     */
    async handleEventClick({ event }) {
      const modal = await modalController.create({
        component: SessionEditModal,
        componentProps: {
          event: event,
        }
      });

      modal.present();

      const { role } = await modal.onWillDismiss();
      if (role === 'save') {
        this.calendarApi().refetchEvents()
      }
    },

    /**
     * FullCalendar - Handle when a date is selected
     * Loads the Create Shift Modal
     * Might want to deprecate or disable this
     * 
     * @param {*} info 
     */
    async handleDateSelect(info) {
      console.log(info)
    }
  }
};
</script>

<style scoped>
.fc-timeline-event {
  text-align: center !important;
}

.fc-event-morning {
  margin: 10px 10px 10px 0px;
  padding: 4px 20px 4px 20px;
  border-radius: 4px;
  background-color: #3CB371;
  color: #fff;
}

.fc-event-afternoon {
  margin: 10px 10px 10px 0px;
  padding: 4px 20px 4px 20px;
  border-radius: 4px;
  background-color: #1E90FF;
  color: #fff;
}

.fc-event-evening {
  margin: 10px 10px 10px 0px;
  padding: 4px 20px 4px 20px;
  border-radius: 4px;
  background-color: #BA55D3;
  color: #fff;
}

.fc-event-night {
  margin: 10px 10px 10px 0px;
  padding: 4px 20px 4px 20px;
  border-radius: 4px;
  background-color: #4B0082;
  color: #fff;
}
</style>